import { Link } from "react-router-dom"
import React, {useState, useEffect} from "react" //React Hook
import Title from "./Title"
import QuantityBtn from "./QuantityBtn"

export default function ProductList() {

    let [productList, setProductList] = useState([])

    //useEffect hook
    useEffect(()=>{

        //1 : 無第二個參數 : component每次render都會觸發
        //2 : Dependency Array是空array時 : 只會在第一次網頁render時會觸發
        //3 : Dependency Array是有變數時 : 第一次網頁render時 + 指定的變數改變 會觸發
        fetch('https://stephanietoo.com/react-basic-product.json')
        .then(response => response.json())
        .then(data => setProductList(data))

    },[]) // <==  Dependency Array


    let [postList, setPostList] = useState([])

    useEffect(()=>{
        fetch('https://stephanietoo.com/post.json')
        .then(response => response.json())
        .then(data => setPostList(data))
    },[])

    const [showPost, setShowPost] = useState(true)

    
    return (
        //React Fragment簡寫
        <>
            <div className="p3">
            <Title mainTitle="React入門水果店" /><hr />

            <div className="flex space wrap1 center">
                {
                    productList.map(product=>(
                        <React.Fragment key={product.id}>

                            <div className="content">
                                <Link to={'/product/'+product.id}>
                                    <img className="thumb" src={process.env.PUBLIC_URL+'/img/'+product.image} alt={product.name} />
                                </Link>

                                <div className="content">
                                    {product.name}  -  {product.price}元/件
                                </div>
                
                                <QuantityBtn productInfo={product} />
                            </div>

                        </React.Fragment>
                    ))
                }
            </div></div>

            <article className="p3" style={{padding:15}}>{showPost && <button onClick={()=>{setShowPost(false)}}>隱藏帖文</button>}
            {!showPost && <button onClick={()=>{setShowPost(true)}}>顯示帖文</button>}</article>

            <div>
                {
                    showPost && postList.map(post=>(
                        <article className="lh" style={{padding:15}} key={post.id}>
                            <h2>上面增加了Sims站和部落格的連結</h2>
                            <h3>Monday 19-08-2024 20:12:44</h3>
                            Sims站已完成，只欠中文版的網站更新記錄，因為要完成部落格的建立，才能把那頁弄好，這裡仍然維持半成品的React示範頁🫠，話說這Sims站是純HTML、CSS和少許JavaScript的網站，相當傳統，而Blog站是採用WordPress引擎的部落格，模板是自己開發的傳統PHP格式，這兩個網站其實可當作示範站，但內容的確是自己的正式作品哦。剛找到一份固定的工作，所以更新速度會比之前慢很多很多，請耐心等待😅<br /><br />

                            <hr />
                            <h2>終於都把這個示範頁做好了</h2>
                            <h3>Friday 08-03-2024 00:49:58</h3>
                            因為變數太多，所以上一篇我沒有把整個過程寫下來，等我練多幾次才寫，以減少出錯率，不過最下面Node.js那部份是已經對了的，可放心參考<br />
                            這幾天就是跟著YouTube教程練習這個React示範頁，每個影片都平均看了超過5次才搞明白，然後花了幾小時來做裝飾，顏色跟回我以前的網站一樣，不過以後會弄個淺色版，方便閱讀（現在還不會弄啦🤣）<br />
                            當然現在的水果店只是示範頁，那個“結帳付款”鍵可以放心大大力按下去，因為是沒有功能的，正式開張的時候，就不是水果店了哦😁<br />
                            <br />

                            <hr />
                            <h2>經過重重困難，終於都把React給引進來了</h2>
                            <h3>Sunday 03-03-2024 17:59:21</h3>
                            好累，有空才把完整的步驟寫下來<br /><br />

                            <hr />
                            <h2>在Plesk Obsidian 18.0.58使用Node.js</h2>
                            <h3>Saturday 02-03-2024 15:48:45</h3>
                            
                            好不容易才搞定這麼一個頁面，主要是我缺少原本系統所需的app.js，因此花了很長時間來找這個啟動文件，終於在 <a href="https://stackoverflow.com/questions/51228526/deploying-a-create-react-app-to-plesk-onyx">Deploying a create-react-app to Plesk Onyx</a> 裡找到方法，馬上記錄下來，免得以後可能要用到的時候，還得重新搜索教程😂<br />

                            跟連結裡面所示的方法有點不同，以下的步驟是建立一個沒有任何內容的起始頁，適合完全沒有接觸過Node.js的新手，但相信大家已經有了操作VS Code的基礎，或是基本的製作網站經驗，所以隨便拿一個已完成的簡單首頁來作為所需的index.html吧<br />

                            先創建node文件夾，把index.html放在裡面<br />

                            創建server.js文件，內容如下<br /><br />

                            <fieldset>
                                        {"const express = require('express');"}<br />
                                        {"const bodyParser = require('body-parser')"}<br />
                                        {"const path = require('path');"}<br />
                                        {"const app = express();"}<br />
                                        {"app.use(express.static(path.join(__dirname, 'node')));"}<br /><br />

                                        {"app.get('/ping', function (req, res) {"}<br />
                                        {"return res.send('pong');"}<br />
                                        {"});"}<br /><br />

                                        {"app.get('/', function (req, res) {"}<br />
                                        {"    res.sendFile(path.join(__dirname, 'node', 'index.html'));"}<br />
                                        {"});"}<br /><br />

                                        {"app.listen(process.env.PORT || 8080);"}
                            </fieldset><br />

                            然後連同裝著index.html的node文件夾一起上傳到httpdocs，這時httpdocs裡面只有一個server.js和一個node文件夾<br />

                            回到主頁，Create Website選Node.js<br />

                            Node.js Dashboard裡面的選項如下<br /><br />

                            <fieldset>
                                    Node.js Version：20.11.1<br />
                                    Package Manager：npm<br />
                                    Document Root：/httpdocs/node<br />
                                    Application Mode：production<br />
                                    Application URL：自動生成的網址<br />
                                    Application Root：/httpdocs<br />
                                    Application Startup File：server.js<br />
                                    Custom environment variables：specify
                            </fieldset><br />
                            
							去Run Node.js commands頁面選npm，打 i express 來安裝express插件<br />

                            這時才來Enable Node.js，點擊Application URL的網址，如果按進去是顯示你創建的index.html內容，就代表Node.js成功運行，接下來可以去參考各種相關教程來打造自己想要的網站了😎<br />

                            *上面所示的node文件夾只是例子，可以隨便命名，但要確保server.js內的兩項 __dirname, '文件夾名稱' 和 Node.js Dashboard 選項的 Document Root：/httpdocs/文件夾名稱 一致<br /><br />
                        </article>
                    ))
                }
            </div>
        </>
    )
}